import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./views/Login/Login";
import Home from "./views/Home/Home";
import Dashboard from "./views/Dashboard/Dashboard";
import Spinner from 'react-bootstrap/Spinner';
import Axios from "axios";
import toastr from 'toastr';
import { getToken, logout} from './config/token';

export class Routes extends Component {

  state = {
    loading: true,
    auth: false
  }

  getLogged = () => {
    Axios
    .get('/api/current-user', {headers: {Authorization: 'Bearer ' + getToken()}})
    .then(response => {
      if(!response.data.success){
        this.setState({
          loading: true,  
          auth: false
        })
        toastr.remove();
        toastr.warning('Session expired!');
        setTimeout(() => {
          logout();
        }, 2000);
      }else{
        this.setState({
          loading: false,  
          auth: true
        })
      }
    }).catch(err=>{
      this.setState({
        loading: false,  
        auth: false
      })
    })
  }

  componentDidMount(){

    this.getLogged()

    setInterval(() => {
      this.getLogged()
    }, 60000);
  }

  render() {
    return (
      <>
        {this.state.loading ? 
          <div className="col-2 text-center mx-auto"><Spinner animation="border" className='colorSpinner'/></div>
        :
          <BrowserRouter>
            <Switch> 
              {!this.state.auth ? <Route exact path="/login" render={() => <Login auth={this.state.auth}/>}/> : null}
              {this.state.auth ? <Route exact path="/dashboard" render={() => <Dashboard auth={this.state.auth}/>}/> : null}

              <Route exact path="/" render={() => <Home auth={this.state.auth}/>}/>
              <Route exact render={() => <Home auth={this.state.auth}/>}/>
            </Switch>
          </BrowserRouter>
        }
      </>
    );
  }
}

export default Routes;
