import React, { Component } from "react";
import Axios from "axios";
import toastr from 'toastr';
import Navbar from '../../components/NavBar/NavBar';
import { setToken } from '../../config/token';
import Spinner from 'react-bootstrap/Spinner';
import './login.css';

export class Login extends Component {

  state = {
    loadingSubmit: false,
    email: '',
    password: '',
  };

  handleLogin = (e) => {
    e.preventDefault();
    let error = false;

    this.setState({loadingSubmit: true})

    if(this.state.email === '' || this.state.password === ''){
      toastr.remove()
      toastr.warning("Please complete all the fields");
      this.setState({loadingSubmit: false})
      error = true;
    }

    if(!error){
      const data = JSON.stringify({
        "email": this.state.email,
        "password": this.state.password,
      });
  
      Axios
      .post('/api/adminLogin', data, {headers: {"content-type": "application/json"}})
      .then(response => {
        if(response.data.success){
          setToken(response.data.data.token);
          toastr.success('Logged!')
          this.setState({loadingSubmit: false})
          setTimeout(() => {
            window.location = "/dashboard"
          }, 2000);
        }else{
          toastr.remove()
          toastr.warning(response.data.error)
          this.setState({loadingSubmit: false})
        }
      }).catch(err=>{
        toastr.remove()
        toastr.warning(err.response.data.error)
        this.setState({
          loading: false,  
          auth: false
        })
      });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <>
        <Navbar auth={this.props.auth} login={true}/>
        <div className="login-main">
          <div className="login-contenedor">
            <div className="row mx-0 px-0" style={{marginTop: '20vh'}}>
              <form className="border-shadow login-form" onSubmit={this.handleLogin}>
                <div className="row my-3">
                  <input onChange={this.handleChange} className="mx-auto w-75 my-2 form-input form-control" type='email' name="email" placeholder="E-mail"/>
                  <input onChange={this.handleChange} className="mx-auto w-75 my-2 form-input form-control" type='password' name="password" placeholder="Password"/>
                </div>
                <div className="row my-2">
                  <button className="col-4 btn btn-sm mx-auto login-button" type="submit" disabled={this.state.loadingSubmit}>
                    {this.state.loadingSubmit ? 
                      <Spinner animation="border" size='sm'/>
                    : 
                      'Send'
                    }
                  </button>
                </div>
                {/* <div className="row mt-3">
                  <a href="/#" className="mx-auto" style={{fontSize:"0.7rem", color:"#078FAA"}}>Forgot password?</a>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;