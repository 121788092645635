import React, { Component } from "react";
// import Axios from "axios";
// import toastr from 'toastr';
import Navbar from '../../components/NavBar/NavBar';
import './home.css';

export class Home extends Component {

  state = {

  };
  
  componentDidMount(){
    
  }

  render() {
    return (
      <>
      <Navbar auth={this.props.auth} home={true}/>
      <div className="home-main superCenter">
        Parachute Admin Panel
      </div>
      </>
    );
  }
}

export default Home;