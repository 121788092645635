import React, { Component } from "react";
import Axios from "axios";
import toastr from 'toastr';
import Navbar from '../../components/NavBar/NavBar';
import Spinner from 'react-bootstrap/Spinner';
import { getToken } from '../../config/token';
import './dashboard.css';

export class Dashboard extends Component {

  state = {
    loading: false,
    email: ''
  };

  handleAdd = (e) => {
    e.preventDefault();

    this.setState({loading: true})

    if(this.state.email === ''){
      toastr.remove()
      toastr.warning("Please complete all the fields");
      this.setState({loading: false})
    }

    const data = JSON.stringify({
      "email": this.state.email,
    });

    Axios
    .post('/api/admin/addHost', data, {headers: {"content-type": "application/json", Authorization: 'Bearer ' + getToken()}})
    .then(response => {
      if(response.data.success){
        toastr.success('Added!')
        this.setState({loading: false})
      }else{
        toastr.remove()
        toastr.warning(response.data.error)
        this.setState({loading: false})
      }
    }).catch(err=>{
      toastr.remove()
      toastr.warning(err.response.data.error)
    });
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  render() {
    return (
      <>
      <Navbar auth={this.props.auth} dashboard={true}/>
      <div className="dashboard-main">
        <div className="dashboard-contenedor">
          <div className="row" style={{marginTop: '20vh'}}>
            <form className="border-shadow dashboard-form" onSubmit={this.handleAdd}>
              <span className='mx-auto'>Add e-mail host</span>
              <div className="row my-3">
                <input onChange={this.handleChange} className="mx-auto w-75 my-2 form-input form-control" type='text' name="email" placeholder="E-mail"/>
              </div>
              <div className="row my-2">
                <button className="col-4 btn btn-sm mx-auto dashboard-button" type="submit" disabled={this.state.loading}>
                  {this.state.loading ? 
                    <Spinner animation="border" size='sm'/>
                  : 
                    'Send'
                  }
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      </>
    );
  }
}

export default Dashboard;